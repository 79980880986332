.EventsContainer{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    &__Content{
        padding: 0rem 1rem;
        & > ul {
            overflow-y: scroll;
            max-height: 360px;
            list-style: none;
            padding: 0;
            margin: 0;
            & > li {
                display: flex;
                margin-bottom: 0.5rem;
                .tilt {
                    width: 1rem;
                }
                .text {
                    width: calc(100% - 2rem);
                    padding-left: 1rem;
                    & > p {
                        margin: 0;
                        padding: 0;
                    }
                }
            }
        }
    }
}

.OtherBlocksContainer{
    display: grid;
    
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    .isMobile & {
        display: flex;
        flex-direction: column;
    }
    &__Article {
        &:nth-child(1n) {
            width: calc(100% - 1rem);
            .isMobile & {
                width: 100%;
            }
        }
        z-index: 1;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        flex: 1;
        display: flex;
        flex-direction: row;
        &__Content {
            padding: 1rem;
            background: none !important;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin: 0;
            }
        
            small {
                font-variant: small-caps;
                text-transform: lowercase;
            }
        
            a {
                text-decoration: none;
                color: #000;
            }
        }
    }
}

.GalleryWrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    &__Item {
        width: 100%;
        &__Image {
            width: 330px;
        }
    }
}

.BlocksContainer{
    display: grid;
    flex-direction: row;
    grid-template-columns: 50% 50%;
    gap: 1rem;
    margin-top: -50px;
    .isMobile & {
        display: flex;
        flex-direction: column;
        margin-right: 1rem;
    }
    // padding: 1rem;
    &__Article{
        z-index: 1;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        flex: 1;
        display: flex;
        flex-direction: row;
        &:first-child {
            margin-left: 1rem;
            width: calc(100% - 1rem);
        }
        &:last-child {
            width: calc(100% - 2rem);
            .isMobile & {
                width: calc(100% - 1rem);
                margin-left: 1rem;
            }
        }
        &__Content {
            padding: 1rem;
            h1,h2,h3,h4,h5,h6 {
                margin: 0;
            }
            small {
                font-variant: small-caps;
                text-transform: lowercase;
            }
            a {
                text-decoration: none;
                color: #000;
            }
            &WImage {
                padding-right: 196px;
            }
        }
        &__Image {
            height: 100%;
        }
        transition: all ease 300ms;
        user-select: none;
        &:hover {
            transform: translateY(-5px);
        }
    }
}