body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  display: flex;
  flex-direction: column;
  &_Row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
    margin-left: 1rem;
    .isMobile & {
      flex-direction: column;

      .BannerWrapper img {
        width: calc(100% - 1rem);
      }

      .GalleryWrapper {
        display: flex;
        flex-direction: column;
        &__Item {
          img {
            width: calc(100% - 1rem);
          }
        }
      }
    }
  }
  &_RowFull {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    margin-top: 2rem;
  }
  &_Header {
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    .isMobile & {
      // position: absolute;
      &_Logo {
        // position: absolute;
        // top: 2rem;
        // left: 2rem;
        // width: 100px;
        // height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    &_Menu {
      display: flex;
      align-items: center;
      .isMobile &:not(.App_Header_Menu_Show) {
        display: none;
      }
      
      &_Ul {
          display: flex;
          flex-direction: row;
          list-style: none;
          gap: 2rem;
          & ul{
            transition: opacity 1s linear 2s;
            opacity: 0;
            display: none;
            position: absolute;
            margin-left: -1rem;
            // top: 100%;
            // left: 0;
            background-color: #fff;
            padding: 1.5rem 1rem 1rem 1rem;
            flex-direction: column;
            gap: 1rem;
            list-style: none;
            z-index: 1000;
          }
          & li {
            &:hover > ul {
                display: flex;
                opacity: 1;
            }
          }
      }
      &_Item {
        text-decoration: none;
        color: #000;
        transition: all 0.3s ease;
        font-size: 17px;
        &.RRSSButton{
          background-color: #EDF2F6;
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -9px;
          border-radius: 6px;
          &:hover {
            background: #204B9F;
            * {
              color: #FFF;
              fill: #FFF;
            }
          }
        }
        &:hover {
          transform: scale(1.1);
        }
        &_Starred {
          background: #204B9F;
          color: #fff;
          padding: 0.75rem 1rem;
          border-radius: 5px;
          font-weight: 600;
          box-sizing: border-box;
          &_Outlined {
            color: #204B9F;
            border: 2px solid #204B9F;
            background: #fff;
            padding: 0.75rem 1rem;
            border-radius: 5px;
            font-weight: 600;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  &_Hero {
    height: 450px;
    background-size: contain;
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 2rem 50px 2rem;
    .isMobile & {
      padding-top: 2rem;
      justify-content: center;
    }
    &_Gradient {
      background: rgb(0, 0, 0);
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 125%);
      z-index: 0;
      left: 0;
      top: calc(100px + 3rem);
      position: absolute;
      width: 100vw;
      height: 500px;
      .isMobile & {
        height: calc(100vh + 5rem);
        top: calc(100px + 5rem);
        display: none;
      }
    }
    .isMobile & {
      // height: 100vh;
      background-size: cover;
    }
    h2 {
      margin: 0;
      padding: 0;
      color: #FFF;
      font-variant: small-caps;
      text-transform: lowercase;
      line-height: 1rem;
      font-weight: 500;
      z-index: 1;
    }
    & > a {
      z-index: 100;
      text-decoration: none;
    }
    h1 {
      margin: 0;
      padding: 0;
      color: #FFF;
      z-index: 100;
      text-decoration: none;
      font-size: 3rem;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 3rem;
      max-width: 67vw;
      z-index: 1;
      .isMobile & {
        max-width: 100vw;
        font-size: 3.5rem;
        line-height: 3.5rem;
      }
      margin-top: 1rem;
    }
    p {
      margin: 0;
      padding: 0;
      color: #FFF;
      font-size: 1.25rem;
      font-weight: 300;
      max-width: 55vw;
      text-shadow: #000 0px 0px 10x;
      z-index: 1;
      .isMobile & {
        max-width: 100vw;
        text-shadow: 0 0 10px rgba(0,0,0,0.5);
        font-size: 1.5rem;
      }
      margin-top: 1rem;
      margin-bottom: 4rem;
    }
  }
  &_Footer {
      padding: 0 2rem;
      padding-bottom: 10rem;
      min-height: 155px;
      background-position: bottom center;
      background-repeat: repeat-x;
      display: flex;
      .isMobile & {
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 6rem;
        background-size: 300%;
      }
      justify-content: space-between;
      padding-top: 4rem;
      h4 {
        text-transform: lowercase;
        font-variant: small-caps;
        font-size: 1rem;
        font-weight: 600;
      }
      &_LinksBox {
        flex: 2;
        display: flex;
        flex-direction: column;
        .isMobile & {
          margin-top: 1rem;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            margin: 0;
            padding: 0;
            margin-top: 0.75rem;
            a {
              text-decoration: none;
              color: #000;
            }
          }
        }
      }
      &_ContactBox {
        flex: 4;
        text-align: right;
        .isMobile & {
          margin-top: 1rem;
        }
      }
  }
}

.isMobile {
  button {
    box-shadow: 0;
    border: 0;
    font-size: 24px;
    background: #204B9F;
    color: #fff;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    font-weight: 600;
    box-sizing: border-box;
  }
}

.isMobile .App_Header_Menu_Show {
  flex: 1;
  padding-bottom: 24px;
  display: flex;
  & > ul {
    display: flex;
    flex: 1;
    flex-direction: column;
    & > li {
      & > ul {
        display: flex !important;
        opacity: 1 !important;
        position: inherit;
        margin-left: 2rem;
        margin-bottom: -1rem;
      }
    }
    & > div {
      display: flex;
      flex-direction: row;
      flex: 1;
      li {
        flex: 1;
        a {
          display: flex;
          margin-right: 4rem;
        }
        &:last-child {
          display: none;
        }
      }
    }
  }
  .App_Header_Menu_Item {
    font-size: 24px;
  }
}

.NewsContainer {
  display: grid;
  grid-template-columns: repeat(3, calc(32vw - (2rem / 3)));
  grid-gap: 16px;
  grid-auto-rows: minmax(100px, auto);
  padding: 2rem;
  article {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      height: 180px;
      object-fit: cover;
      .isMobile & {
        width: 100%;
        height: 100%;
      }
    }
    h4 {
      padding: 1rem 0rem 0rem 0rem;
      margin: 0;
    }
    a {
      text-decoration: none;
      margin: 0;
      padding: 0;
      color: #204B9F; 
      h1 {
        margin: 0;
        padding: 0;
      }
    }
  }
  .isMobile & {
    display: flex;
    flex-direction: column;
  }
}

.relative.overflow-hidden.shadow-md.rounded-lg {
  display: flex;
  align-content: center;
  justify-content: center;
  & > table {
    width: 30vw;
    max-width: 30vw;
    min-width: 780px;
    .isMobile & {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
    }
  }
}