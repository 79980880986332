.App {
  &__Loading {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_TopBar {
    background: #204B9F;
    display: flex;
    height: 3rem;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 1rem;
    align-items: center;
    .isMobile & {
      flex-direction: column;
      padding: 1rem;
    }
    a {
      text-decoration: none;
      color: #FFF;
    }
    &__Left,
    &__Right{
      display: flex;
      gap: 2.5rem;
      color: #FFF;
      .Icon {
        margin-right: 8px;
      }
    }
  }
}